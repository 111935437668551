
export const CURRENCY =  '€';
export const DIGITS = 2;
export const URL = 'https://api.fbsshop.de';
export const IMAGEURL = 'https://demka.aysales.de';
export const VENDORID = '10001120';
export const COMPANYNAME = 'FALKE Bilgi Teknolojileri Ltd. Sti.'
export const VERSION = "Version 2.0.0"
export const BANNER = false;
export const BRANDS = false;
export const FLAG_ITEM_ON_HAND = false;
